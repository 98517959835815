$(document).ready(function() {
  $('form').on('change', '.offer_type_radio', function(event) {
    var vouchersDiv = $('#vouchers_content');
    var voucherCodeBlock = $(".voucher_code_block");

    showFormContent(vouchersDiv);
    hideFormContent(voucherCodeBlock);

    if(this.checked && this.value == 'generic_voucher') {
      showFormContent(voucherCodeBlock);
    } else {
      hideFormContent(vouchersDiv);
    }
  });

  $('form').on('change', '.redemption_type_radio', function(event) {
    var customTypeForm = $('#redemption-type-rule-form');
    hideFormContent(customTypeForm);

    if(this.checked && this.value == 'custom') {
      showFormContent(customTypeForm);
    } else {
      hideFormContent(customTypeForm);
    }
  });
  // OnLoad triggers
  $('.offer_type_radio:checked').trigger('change');
  $('.redemption_type_radio:checked').trigger('change');
});
