// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
require("@rails/ujs").start()

import "bootstrap"
import "../stylesheets/application"
import "daterangepicker"
import "select2"
import "select2/dist/css/select2.css"

var moment = require('moment');
var jQuery = require('jquery');
require('jquery-ui')

//= require jquery
//= require popper
//= require turbolinks
//= require bootstrap
//= require_tree.

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
global.moment = moment;
window.moment = moment;

// Global functions
window.showFormContent = function(el) {
  el.show();
  el.find('input,select').attr('disabled', false);
}
window.hideFormContent = function(el) {
  el.hide();
  el.find('input,select').attr('disabled', true);
}

// Other JS packs
require("packs/offers")
require("packs/memberships")
require("packs/commissions")
require("packs/client_member_roles_and_prmsn")

// JS Logic
$(document).ready(function() {
  $('.js-states').select2();
  $('.add-select2').select2();

  function deleteFromArray(array, element){
    var itemIndex = array.indexOf(element);
    if (itemIndex !== -1) {
      array.splice(itemIndex, 1);
    }
  }

  $('.grouped_collection_select2').on("select2:selecting", function(e) {
    let selectedText = e.params.args.data.text
    let targetElement = $(this)
    let currentVal = targetElement.val() || []
    let currentCollection = targetElement.data('collection')
    if (targetElement.data('map')[selectedText]){
      if (currentCollection && currentCollection.includes(selectedText)){
        deleteFromArray(currentCollection,selectedText)
        $.each(targetElement.data('map')[selectedText], function(_index, country){
          deleteFromArray(currentVal, country[1])
        })
      } else if (currentCollection) {
       currentCollection.push(selectedText)
       $.each(targetElement.data('map')[selectedText], function(_index, country){
          currentVal.push(country[1])
        })
      } else {
        $.each(targetElement.data('map')[selectedText], function(_index, country){
          currentVal.push(country[1])
        })
        currentCollection = [selectedText]
      }
      targetElement.data('collection', currentCollection)
      targetElement.val(currentVal)
      targetElement.trigger('change').select2("close");
      return(false)
    }
  });

  $('.select2SelectRemoveAll').on('click', function(){
    $(".select2SelectAllTarget").val(null).trigger('change');
  });

  $('.select2SelectAll').on("click", function (e) { 
    e.preventDefault();
    let select_field = $(this).parents('.form-group').find('.select2SelectAllTarget').first();
    select_field.val(select_field.data().ids)
    $('.add-select2').trigger('change')
  });

  $('.remote-fetch').on('change', function(){
    let element = $(this);
    if (element.val() === ''){
      return
    }
    let data = element.data('path_parameters') || {}
    data[element.data('name') || element.attr('name')] = element.val()
    $.ajax({
      url: element.data().url,
      data: data
    })
  })
  $('.update-remote-fetch').on('change', function(){
    let element = $(this);
    let data = {}
    data[element.attr('name')] = element.val()
    element.parents('form').find('.remote-fetch').data('path_parameters', data)
    $('.remote-fetch').trigger('change');
  })
  $('.single-select').on('change', function(){
    let element = $(this);
    if (element.val() == '') {
      $('.single-select').attr('disabled', false)
    } else  {
      $.each($('.single-select'), function(index, dom_element){
        if (element.attr('id') != $(dom_element).attr('id')){
          $(dom_element).attr('disabled', true)
        }
      })
    }
  })
  let templateManager = {
    counter: 0,
    template: $("#offer_location_template").html(),
    renderAfter: $("#offer_location_template"),
    replaceString: "service_wrappers_perks_service_offer[offer_locations_attributes]",
    initialData: $("#add_locations").data('collection'),
    //method
    renderTemplate: function(data){
      let formattedHtml = templateManager.template.replaceAll(
        templateManager.replaceString, 
        templateManager.replaceString + '['+ templateManager.counter + ']'
      );
      let containerId= 'container-' + templateManager.counter
      templateManager.renderAfter.after(
        '<div class="field form-group container" '+'id='+containerId+'>' + formattedHtml+ '</div>'
      );
      templateManager.fillupWithData(containerId, data);
      addAllEventListeners();
      templateManager.triggerCountryChange(containerId);
      templateManager.triggerCityChange(containerId);
      templateManager.counter ++ ;
    },

    fillupWithData: function(containerId, data){
      $.each(data, function(key, value){
        let element = $('#'+containerId).find('[data-target='+key+']')
        if (element) {
          element.data('value', value);
          element.val(value);
        }
      })
    },

    renderInitialData: function(){
      $.each(templateManager.initialData, function(_index, data){
        templateManager.renderTemplate(data)
      })
    },

    setup: function(){
      templateManager.renderInitialData()
      templateManager.renderAfter.html(' ')
    },

    triggerCityChange: function(containerId){
      $("#"+containerId).find(".offer-location-city").trigger('change')
    },
    triggerCountryChange: function(containerId){
      $("#"+containerId).find(".offer-location-country").trigger('change')
    }
  }

  function addAllEventListeners(){
    $(".offer-location-country").on('change', function(){
      let selected_country = $(this).val()  || $(this).data('value')
      if (selected_country == null){
        console.log("empty value for country")
        return
      }
      let city_select_field = $(this).parents('.container').find('.offer-location-city');
      let selected_city = city_select_field.data('value')
      $.ajax({
        url: "/offers/cities",
        data: { country_id: selected_country}
      }).done(function(resp) {
        city_select_field.html(' ');
        $.each(resp.data, function(index,element) {
          city_select_field.append($("<option></option>")
            .attr("value", element.attributes.id).text(element.attributes.name));
        });
        if (selected_city){
          city_select_field.val(selected_city)
          city_select_field.removeData('value')
        }
      });
    })
    $(".offer-location-city").on('change', function(){
      let selected_city = $(this).val()  || $(this).data('value')
      if (selected_city == null){
        console.log("empty value for city")
        return
      }
      let locality_select_field = $(this).parents('.container').find('.offer-location-locality');
      let selected_locality = locality_select_field.data('value')
      $.ajax({
        url: "/offers/localities",
        data: { country_id: $(this).parents('.container').find('.offer-location-country').val(), city_id: selected_city}
      }).done(function(resp) {
        locality_select_field.html(' ');
        $.each(resp.data, function(index,element) {
          locality_select_field.append($("<option></option>")
            .attr("value", element.attributes.id).text(element.attributes.name));
        });
        if (selected_locality){
          locality_select_field.val(selected_locality)
          locality_select_field.removeData('value')
        }
      });
    })
  }
  if ($("#add_locations").length > 0) {
    templateManager.setup();
  }
  $("#add_locations").on('click', function(e){
    e.preventDefault();
    templateManager.renderTemplate({})
  })

  // Generic Nested Form Template Manager
  $('.nested-form-container').each(function(_,nestedFormContainer) {
    let addBtn = $(nestedFormContainer).find('.nested-form-add-btn')
    let nestedFormTemplateManager = {
      counter: 0,
      template: $(nestedFormContainer).find('.nested-form-template').html(),
      renderAfter: $(nestedFormContainer).find('.nested-form-template'),
      replaceString: addBtn.data('replacestring'),
      initialData: addBtn.data('collection'),
      //method
      renderTemplate: function(data){
        let formattedHtml = nestedFormTemplateManager.template.replaceAll(
          nestedFormTemplateManager.replaceString, 
          nestedFormTemplateManager.replaceString + '['+ nestedFormTemplateManager.counter + ']'
        );
        let containerId= 'container-' + nestedFormTemplateManager.counter
        nestedFormTemplateManager.renderAfter.after(
          '<div class="field form-group container" '+'id='+containerId+'>' + formattedHtml+ '</div>'
        );
        nestedFormTemplateManager.fillupWithData(containerId, data);
        nestedFormTemplateManager.counter ++ ;
      },

      fillupWithData: function(containerId, data){
        $.each(data, function(key, value){
          let nestedFormContainer = $('#'+containerId).find('[data-target='+key+']')
          if (nestedFormContainer) {
            nestedFormContainer.data('value', value);
            nestedFormContainer.val(value);
          }
        })
      },

      renderInitialData: function(){
        $.each(nestedFormTemplateManager.initialData, function(_index, data){
          nestedFormTemplateManager.renderTemplate(data)
        })
      },

      setup: function(){
        nestedFormTemplateManager.renderInitialData()
        nestedFormTemplateManager.renderAfter.html(' ')
      }
    }
    nestedFormTemplateManager.setup();
    $(addBtn).on('click', function(e){
      e.preventDefault();
      nestedFormTemplateManager.renderTemplate({})
    })
  })

  // Form toggler - Hide/Show content based on click
  // Disables the form inputs under the hidden area
  $('form').on('click', '.toggle-parent', function(event) {
    var targetEl = $($(this).data('toggle-target'));
    if(targetEl.hasClass('d-none')) {
      targetEl.find('input,select,textarea').attr('disabled', false);
    } else {
      targetEl.find('input,select,textarea').attr('disabled', true);
    }
    targetEl.toggleClass('d-none');
  });

  // Expand options tag with nested associations
  $('form').on('click', 'option.parent', function() {
    var parentId = $(this).data('id');
    var targetEl = $('option.nested.' + parentId);
    targetEl.addClass('d-none');
    if($(this).prop('selected')) { targetEl.removeClass('d-none'); }
  });
  $('form').on('click', 'option.nested', function() {
    var parentEl = $('option.parent.' + $(this).data('parentId'));
    var selectEl = parentEl.parent();
    var selectVals = selectEl.val();
    parentEl.prop('selected', true);
    if(selectVals.indexOf(parentEl.val()) == -1) {
      selectVals.push(parentEl.val());
      selectEl.val(selectVals);
    }
  });
  $('select[multiple] option.parent').each(function() {
    if($(this).prop('selected')) {
      $(this).trigger('click');
    }
  });

  // Nested Form events - Add and Remove
  $('form').on('click', '.add_fields', function(event) {
    var time = new Date().getTime();
    var id = $(this).data('id');
    var fields = $(this).data('fields');
    var max = $(this).data('max');
    // Replace ID and NAME attributes with timestamp
    var fieldsContent = fields.replaceAll('_'+id+'_', '_'+time+'_').replaceAll('['+id+']', '['+time+']');
    var formFields = $(this).parents('form').find('.form-fields');
    $(formFields).append(fieldsContent);
    if(max && formFields.find('.record-row:visible').length >= max) $(this).hide();
    return event.preventDefault();
  });
  $('form').on('click', '.remove_record', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('.record-row').hide();
    var addMoreBtn = $(this).parents('form').find('.add_fields');
    var max = $(addMoreBtn).data('max');
    if(max && $(this).closest('.form-fields').find('.record-row:visible').length < max) addMoreBtn.show();
    return event.preventDefault();
  });

  var old_positions = $('.positioning-form .position-val').map(function() {
    return parseInt(this.value);
  }).get();
  // Get all the Previous positions in old_positions
  // Get all the current positions from the form in new_positions
  // CASE 1: if old_position of an elem is < new_position
  // Decrement all the position vals between these two positions. Edge position vals handled
  // CASE 2: if old_positions of an elem is > new_position
  // Increment all the position vals between these two positions. Edge position vals handled.
  // Now new_positions will become old_positions and new_positions is ready to hold any consecutive update in the form.
  $('.positioning-form').on('change', '.position-val', function(event) {
    var new_positions = $('.position-val').map(function() {
      return parseInt(this.value);
    }).get();
    var max_value = Math.max.apply(Math, old_positions);
    var old_position_val = parseInt(old_positions[$(this).data('positionIndex')]);
    var old_position_index = old_positions.indexOf(old_position_val);
    var new_position_val = parseInt(this.value);
    var new_position_index = old_positions.indexOf(new_position_val);
    if (old_position_val < new_position_val) {
      for (var i = 0; i < new_positions.length; i++) {
        if (new_positions[i] !== 1 && new_positions[i] >= old_position_val && new_positions[i] <= new_position_val && i != old_position_index) {
          var updated_position_val = new_positions[i] - 1;
          var elem = $("*[data-position-index='" + i + "']")[0];
          elem.value = updated_position_val;
          new_positions[i] = updated_position_val;
        }
      }
    } else {
      for (var i = 0; i < new_positions.length; i++) {
        if (new_positions[i] !== max_value && new_positions[i] >= 1 && new_positions[i] <= old_position_val && new_positions[i] >= new_position_val && i != old_position_index) {
          var updated_position_val = new_positions[i] + 1;
          var elem = $("*[data-position-index='" + i + "']")[0];
          elem.value = updated_position_val;
          new_positions[i] = updated_position_val;
        }
      }
    }
    old_positions = new_positions;
  });

  $("#checkAll").change(function () {
      $("input:checkbox").prop('checked', $(this).prop("checked"));
  });

  $(".rule_type").change(function() {
    if (['email', 'tier', 'mobile'].includes($(this).val())) {
      $("#bundle_rule_value").prop("disabled", true)
    } else {
      $("#bundle_rule_value").prop("disabled", false)
    }
  });

  $('#config-modal').on('show.bs.modal', function (event) {
    var rowKey = $(event.relatedTarget).data('key')
    $(this).find('tr[data-id]').each(function(_,element) {
      if ($(element).data('id') == rowKey) {
        $(element).show();
      } else {
        $(element).hide();
      }
    })
  });
  $('.delete_config').click(function(event) {
    event.stopPropagation();
    var rowKey = $(this).data('key');
    if(confirm("Do you want to delete?")) {
      $('tr[data-id]').each(function(_,element) {
        if ($(element).data('id') == rowKey) {
          $(element).remove();
        }
      })
      $("#edit-project-config").submit();
    }
    else { return false; }       
    event.preventDefault();
  });

  $('#rules-modal').on('show.bs.modal', function (event) {
    var rowKey = $(event.relatedTarget).data('key')
    $(this).find('tr[data-id]').each(function(_,element) {
      if ($(element).data('id') == rowKey){
        $(element).show();
      } else {
        $(element).hide();
      }
    })
  });

  $('.delete_rule').click(function(event) {
    event.stopPropagation();
    var rowKey = $(this).data('key');
    if(confirm("Do you want to delete?")) {
      $("#rule_delete_"+ rowKey).attr('value',1)
      $("#edit-bundle-rules").submit()
    }
    else { return false; }       
    event.preventDefault();
  });

  setTimeout("$('.error_msgs').fadeOut('slow')", 6000);
  setTimeout("$('.success_msgs').fadeOut('slow')", 3000);

  $('[data-behaviour~=daterangepicker]').daterangepicker({
    autoUpdateInput: false,
    locale: {
      format: 'DD/MM/YYYY'
    }
  });

  $('[data-behaviour~=daterangepicker]').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ',' + picker.endDate.format('DD/MM/YYYY'));
  });

  $.each($('[data-behaviour~=daterangepicker]'), function(index, element){
    $(element).val($(element).data('value'))
  });
  
  $('[data-behaviour~=datepicker]').datepicker({
    dateFormat: 'dd-mm-yy',
    minDate: "+0d" // can use data attribute, if required
  });
  $('[data-behaviour~=datepicker].mindate-1year').datepicker('option', 'minDate', '-1y');
  $('[data-behaviour~=datepicker].mindate-1month').datepicker('option',
    { minDate: '-1m', maxDate: 0 }
  );
  $('.select-hide-input').on('change', function(){
    let selectedValue = $(this).val()
    $('.select-hide-option').addClass('d-none').find('select').attr('disabled', true)
    $('.select-hide-option[data-activeif="' + selectedValue + '"]').removeClass('d-none').find('select').attr('disabled', false)
  })

  if ($("#contexual_stats_date").data('date')){
    var myDate = new Date(Date.parse($("#contexual_stats_date").data('date')));
  } else {
    var myDate = new Date
  }

  $('#contexual_stats_date').datepicker({
      yearRange: "-5:+0",
      changeMonth: true,
      changeYear: true,
      defaultDate: new Date(myDate.getFullYear(), myDate.getMonth(), 1),
      showButtonPanel: true,
      dateFormat: 'MM yy',
      onClose: function(dateText, inst) {
        myDate = new Date(inst.selectedYear, inst.selectedMonth, 1)
        inst.settings.defaultDate = myDate
        $(this).datepicker('setDate', myDate).datepicker( "refresh" );
      },

  }).datepicker('setDate',  new Date(myDate.getFullYear(), myDate.getMonth(), 1));

  $('.select-hide-input').trigger('change');

  $('input[type=file].validate-file-size').on('change', function() {
    var sizeExceeded = false;
    $.each(this.files, function() {
      if (this.size && this.size > 2*(1000*1000)) {
        sizeExceeded = true;
      };
    });
    if (sizeExceeded) {
      window.alert('Error! File size should be less than 2MB');
      $(this).val('');
    };
  });

  $( "#manage-client-commissions" ).submit(function() {
    var error_cells = []
    var is_valid = true
    is_valid = compareCommissionValues()
    $('table').each(function(index,tab){
      var arr = Array.prototype.slice.call(tab.rows)
      var is_hidden = tab.closest("div").style.display == 'block' ? true : false
      var count_cells = []
      var input_values = []
      var value_cells = []
      arr.forEach(function(row,i){
        if(i != 0){
          count_cells.push(row.cells[0].children[0])
          value_cells.push(row.cells[1].children[0])
          checkIsEmpty(count_cells, value_cells, error_cells, is_hidden)
        }
      })

      if(error_cells.length > 0){
        is_valid = false
      }

      count_cells.forEach(function(cell,i){
        if(input_values.includes(cell.value)){
          setErrorField(cell)
          return is_valid = false
        }else {
          input_values.push(cell.value)
        }

        if(i != 0 ){
          var prev_value = input_values[input_values.length - 2]
          if(parseFloat(prev_value) > parseFloat(cell.value)){
            setErrorField(cell)
            return is_valid = false
          }
        }
      })
    })
    return is_valid
  });

  $( "#manage-client-selling-price" ).submit(function() {
    var client_ids = []
    var is_valid = true
    $("select:visible option:selected").each(function() {
      if(client_ids.includes(this.value)) {
        this.parentElement.style.borderColor = 'red'
        alert("Please avoid duplication selection of clients.")
        return is_valid = false
      }else {
        client_ids.push(this.value)
      }
    })
    return is_valid
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
