
$(document).ready(function() {

  $(function () {
    var s2= $('.client_member_form_modules').select2({
      closeOnSelect : false,
      placeholder : "Select Modules",
      allowHtml: true,
      allowClear: true,
      tags: true
    });

  });

  $('.client_member_form_modules').on('select2:select', function (e) {
    var data = e.params.data;
    var existing_table = document.getElementById(e.params.data['id'])
    if(existing_table == null){
      var table = document.getElementById('reference_table_container')
      var table_clone = table.cloneNode(true)
      var div_container = document.getElementById('roles_and_permissions_container_div')
      clientMemberRoleManager.configureTable(table_clone, data)
      div_container.append(table_clone)
    }else {
      existing_table.style.display = ''
    }
  }); 

  $('.client_member_form_modules').on('select2:unselect', function (e) {
    var data = e.params.data;
    id = data["id"]
    var table = document.getElementById(id)
    table.style.display = 'none'
  });

  let clientMemberRoleManager = {
    configureTable: function(table, data){
      var rows = Array.prototype.slice.call( table.rows )
      table.style.display = ''
      table.id = data['id']
      table.rows[1].cells[0].innerHTML = data['text']
      clientMemberRoleManager.alterFieldNames(rows, data) 
    },

    alterFieldNames: function(rows, data){
      var project_id = ''
      rows.forEach(function(row,i){
        if(i==0 || i == 1){
          return
        }else{
          project_id = row.className
          var cells = Array.prototype.slice.call( row.cells )
          clientMemberRoleManager.setFieldForRow(cells, data, project_id)
        }
      })
    },

    setFieldForRow: function(cells,data, project_id){
      cells.forEach(function(cell, i){
        switch(i){
          case 0:
            clientMemberRoleManager.setCheckBox(cell, data, project_id)
            break;
          case 2:
            clientMemberRoleManager.setRoleTag(cell, data, project_id)
            break;
          case 3:
            clientMemberRoleManager.setPermissionTag(cell, data, project_id)
            break;
          default:
            break;
        }
      })
    },

    setCheckBox: function(cell, data, project_id){
      var check_box = cell.children[0]
      check_box.name = "check[".concat(data["id"]).concat('][').concat(project_id).concat(']')
      check_box.id = "check_".concat(data["id"]).concat('_').concat(project_id)
    },

    setRoleTag: function(cell, data, project_id){
      var select_tag = cell.children[0]
      select_tag.name = "role[".concat(data["id"]).concat('][').concat(project_id).concat(']')
      select_tag.id = "role_".concat(data["id"]).concat('_').concat(project_id)
    },

    setPermissionTag: function(cell, data, project_id){
      var select_tag = cell.children[0]
      select_tag.name = "permission_type[".concat(data["id"]).concat('][').concat(project_id).concat(']')
      select_tag.id = "permission_type_".concat(data["id"]).concat('_').concat(project_id)
    },

    hideModulesRolesAndPermissions: function(){
      table_ids = $('#service_wrappers_client_service_client_member_allowed_features').val()
      clientMemberRoleManager.removeExistingTable(table_ids)
      $('#service_wrappers_client_service_client_member_allowed_features').val(null).trigger('change');
      document.getElementById('modules_allowed_feature_div').style.display = 'none'
      document.getElementById('roles_and_permissions_container_div').style.display = 'none'
    },

    showModulesRolesAndPermissions: function(){
      document.getElementById('modules_allowed_feature_div').style.display = ''
      document.getElementById('roles_and_permissions_container_div').style.display = ''
      $('#service_wrappers_client_service_client_member_client_id').change()
    },

    removeExistingTable: function(ids) {
      ids.forEach(function(id){
        document.getElementById(id).style.display = 'none'
      })
    },

    removeAddedFeatures: function(){
      $('#roles_and_permissions_container_div table').each(function(tab){
        this.style.display = 'none'
      })
    },

    checkEmailForPoshvineAdmin: function(){
      is_valid = true
      var roles_val = $('#service_wrappers_client_service_client_member_roles').val()
      if(roles_val == 'poshvine_admin'){
        email = $('#service_wrappers_client_service_client_member_email').val()
        if(['poshvine.com', 'razorpay.com'].includes(email.split('@')[1])){
          return is_valid
        }else {
          is_valid = false
          alert("For Poshvine Admin roles, the email should contain @poshvine.com")
          return is_valid
        }
      }
    }
  }

  $(document).on('change', '.client-feature-select-role', function(){
    val = $(this)[0].id
    permission_type_id_arr = val.split('_')
    permission_type_id_arr[0] = 'permission_type_'
    permission_type_id = '#' + permission_type_id_arr[0] + permission_type_id_arr[1] + "_" + permission_type_id_arr[2]
    if($(this).val() == "admin"){
      $(permission_type_id).val('write')
      $(permission_type_id).attr("style", "pointer-events: none;")
    }else {
      $(permission_type_id).val('read')
      $(permission_type_id).attr("style", "pointer-events: '';")
    }
  })

  $(".client-member-roles").change(function(){
    var val = $(this).val()
    if(val == 'poshvine_admin'){
      document.getElementById('other_roles_client_div').style.display = 'none'
      document.getElementById('poshvine_admin_allowed_clients_div').style.display = 'block'
      clientMemberRoleManager.hideModulesRolesAndPermissions()
    }else if(val == 'super_admin'){
      document.getElementById('other_roles_client_div').style.display = 'block'
      document.getElementById('poshvine_admin_allowed_clients_div').style.display = 'none'
      clientMemberRoleManager.hideModulesRolesAndPermissions()
    }else {
      document.getElementById('other_roles_client_div').style.display = 'block'
      document.getElementById('poshvine_admin_allowed_clients_div').style.display = 'none'
      clientMemberRoleManager.showModulesRolesAndPermissions()
    } 
  })

  $( "#new_client_member_form" ).submit(function() {
    var is_valid = true
    is_valid = clientMemberRoleManager.checkEmailForPoshvineAdmin()
    return is_valid
  });

  $( "#edit_client_member_form" ).submit(function() {
    var is_valid = true
    is_valid = clientMemberRoleManager.checkEmailForPoshvineAdmin()
    return is_valid
  });

  window.changeFeatures = function(val, features){
    $('#service_wrappers_client_service_client_member_allowed_features').empty()
    clientMemberRoleManager.removeAddedFeatures()
    var options = features[val.value]
    if(options != undefined){
      Object.keys(options).forEach(function (key) { 
        var value = options[key]
        var data = {
            id: value,
            text: key
        };

        var newOption = new Option(data.text, data.id, false, false);
        $('#service_wrappers_client_service_client_member_allowed_features').append(newOption).trigger('change');
      })
    }else {
      $('#service_wrappers_client_service_client_member_allowed_features').append({}).trigger('change');
    }
  }
});
