  window.toggleCommissionType = function(val){
    text_field_id = val.id.replace("commission_type", "commission_value")
    value_type_id = val.id.replace("commission_type", "value_type")
    label_val = "label[for='" + text_field_id + "']"
    cmsn_value_field = document.getElementById(text_field_id)
    value_type_field = document.getElementById(value_type_id)
    slab_cmsn_div = setSlabCommissionDivValue(val)
    if(val.value == 'percent'){
      disable_value_type(true, value_type_field)
      cmsn_value_field.parentElement.style.visibility = ''
      $(label_val).show()
      cmsn_value_field.closest('div').style.display = "block"
      slab_cmsn_div.style.display = "none"
      cmsn_value_field.max = "100"
      cmsn_value_field.value = cmsn_value_field.value.length == 0 ? '0' : cmsn_value_field.value
    }else {
      disable_value_type(false, value_type_field)
      $(label_val).hide()
      cmsn_value_field.closest('div').style.display = "none"
      slab_cmsn_div.style.display = "block"
    }
  }

  window.disable_value_type = function(val, value_type){
    if(val){
      value_type.setAttribute('readonly', 'readonly')
      value_type.value = 'percentage'
    }else {
      value_type.removeAttribute('readonly');
    }
  }

  window.addMore = function(ele){
    var $lastRow = $(ele).closest('tr')
    var total_row_count = $('#slab_commission_table tr').length
    var $newRow = $lastRow.clone()
    setTextFieldDefaultValues($newRow, total_row_count)
    $newRow.find(":text").val(0);
    $newRow.find("#slab_commission_delete")[0].style.display = "inherit"
    $lastRow.after($newRow);
  }

  window.deleteRow = function(ele) {
    ele.closest('tr').remove()
  }

  window.setTextFieldDefaultValues = function(new_row, total_row_count){
    table_cells = new_row[0].cells
    for(let i=0; i<table_cells.length; i++){
      cell = table_cells[i]
      input_field = cell.firstElementChild
      new_name = change_input_field_name(input_field.name, total_row_count + 1)
      input_field.name = new_name
      input_field.value = '0'
    }
  }

  window.change_input_field_name = function(input_name, total_row_count){
    val = input_name.split('_')
    val[val.length - 1] = "0" + total_row_count + "]"
    return val.join('_')
  }

  window.setSlabCommissionDivValue = function(val){
    slab_commission_div = val.parentElement.parentElement.nextElementSibling
    id_val = val.id.split('_')[9]
    slab_commission_div.id += id_val
    return slab_commission_div
  }

  window.checkIsEmpty = function(count_cells, value_cells, error_cells, is_hidden){
    res_cells = count_cells.concat(value_cells)
    res_cells.forEach(function(val, _){
      if (is_hidden && val.value == ''){
        setErrorField(val)
        error_cells.push(val)
      }
    })
  }

  window.setErrorField = function(cell){
    cell.style.borderColor = "red"
  }

  window.compareCommissionValues = function(){
    var is_valid = true
    var posh_cmsn = parseFloat(document.getElementById('posh_cmsn_value').value)
    $("[id$='commission_value']:visible").each(function(_, val){
      cmsn_val = 
      cmsn_val = parseFloat(document.getElementById('service_wrappers_perks_service_gift_card_client_commissions_attributes_1630394416901_commission_value').value)
      if(posh_cmsn && cmsn_val != NaN ){
        if(!(cmsn_val <= posh_cmsn - 0.5)){
          alert("Client Commission should always be less than poshvine commission.")
          val.style.borderColor = 'red'
          return is_valid = false
        }else {
          val.style.borderColor = '#ced4da'
        }
      }else {
        alert("Poshvine Commission is not defined")
        return is_valid = false
      }
    })
    return is_valid
  }